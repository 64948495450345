import type { IconProps } from "./SvgIcon";
import SvgIcon from "./SvgIcon";

export default function ResetIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      paths={
        <>
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            fill="currentColor"
            d="M21 10h-8.01V7L9 11l3.99 4v-3H21v5H3V5h18v3h2V5c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2v-5H23c0-1.1-.9-2-2-2z"
          />
        </>
      }
    />
  );
}
