import Alert from "@tamarack/ui/Alert";
import Button from "@tamarack/ui/Button";
import { useEffect, useRef, type ElementType } from "react";
import ButtonDialog from "./ButtonDialog";
import Divider from "./Divider";
import Input from "./Input";
import Logo from "./Logo";
import WideForest from "./WideForestImage";
import BurgerMenuIcon from "./icons/BurgerMenuIcon";
import KeyIcon from "./icons/KeyIcon";
import LogoutIcon from "./icons/LogoutIcon";
import PersonIcon from "./icons/PersonIcon";
import ResetIcon from "./icons/ResetIcon";

type LoginFormProps = {
  tenantId?: string | null;
  tenantName?: string | null;
  error?: string;
  loading?: boolean;
  testUsername?: string;
  testPassword?: string;
  testTenant?: string;
  testMcToken?: string;
  Form: ElementType;
  redirectTo?: string | null;
  pathname?: string;
  search?: string;
  version?: string;
  onChangeTenant?: () => void;
  loginType?: LoginType;
  onLoginTypeChange?: (loginType: LoginType) => void;
  onResetMachineCenter?: () => boolean | undefined;
  isOnline?: boolean;
};

export type LoginType = "authKey" | "password";

export default function LoginForm({
  tenantId,
  tenantName,
  error,
  loading,
  testUsername,
  testPassword,
  testTenant,
  testMcToken,
  Form,
  redirectTo,
  pathname,
  search,
  version,
  onChangeTenant,
  loginType,
  onLoginTypeChange,
  onResetMachineCenter,
  isOnline,
}: LoginFormProps) {
  const usernameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (error) {
      usernameRef.current?.focus();
    }
  }, [error]);

  const formAction = pathname || search ? `${pathname}${search}` : undefined;

  return (
    <section className="h-screen overflow-hidden">
      {version ? (
        <div className="fixed bottom-d right-d pl-1 text-sm font-normal text-white">v{version}</div>
      ) : null}

      {onChangeTenant && onLoginTypeChange ? (
        <ButtonDialog
          variant="text"
          buttonClassName="fixed left-1 top-d flex items-center gap-1 pl-1 text-sm text-black"
          cardClassName="p-0 flex flex-col gap-0 overflow-hidden"
          title={<BurgerMenuIcon />}
          placement="bottom-start"
        >
          {(close) => {
            return (
              <>
                {tenantId ? (
                  <Button
                    className="!rounded-none"
                    variant="text"
                    fullWidth
                    align="left"
                    onClick={() => {
                      onChangeTenant();
                      close();
                    }}
                  >
                    <LogoutIcon className="scale-x-[-1] transform" /> Change Tenant
                  </Button>
                ) : null}

                {tenantId ? (
                  <Button
                    variant="text"
                    className="!rounded-none"
                    fullWidth
                    align="left"
                    onClick={() => {
                      onLoginTypeChange(loginType === "authKey" ? "password" : "authKey");
                      close();
                    }}
                  >
                    {loginType === "authKey" ? (
                      <>
                        <PersonIcon /> Use username and password
                      </>
                    ) : (
                      <>
                        <KeyIcon /> Use Authentication key
                      </>
                    )}
                  </Button>
                ) : null}

                {tenantId && onResetMachineCenter ? <Divider /> : null}

                {onResetMachineCenter ? (
                  <Button
                    className="!rounded-none"
                    variant="text"
                    fullWidth
                    align="left"
                    onClick={() => {
                      const shouldClose = onResetMachineCenter();

                      if (shouldClose) {
                        close();
                      }
                    }}
                  >
                    <ResetIcon /> Reset Machine Center
                  </Button>
                ) : null}
              </>
            );
          }}
        </ButtonDialog>
      ) : null}

      <div className="absolute left-1/2 top-[calc(50%-100px)] w-full max-w-[500px] -translate-x-1/2 -translate-y-1/2 p-2">
        <h2 className="flex items-center gap-d pb-4 text-h4">
          <Logo size={40} />
          {tenantName ? (
            <>
              <Divider orientation="vertical" />
              {tenantName.toLocaleUpperCase()}
            </>
          ) : null}
        </h2>

        {tenantId || tenantName ? (
          <Form className="flex flex-col gap-2" method="post" action={formAction}>
            {loginType === "authKey" ? (
              <Input
                name="apiKey"
                label="Authentication Key"
                key="apiKey"
                placeholder="Enter Authentication Key"
                defaultValue={testMcToken}
                required
                autoFocus
                invalidMessage="Authentication Key is required"
                className="!bg-tm-background"
              />
            ) : (
              <>
                <Input
                  name="username"
                  inputRef={usernameRef}
                  label="Username"
                  key="username"
                  placeholder="Enter username"
                  defaultValue={testUsername}
                  required
                  autoFocus
                  invalidMessage="Username is required"
                  className="!bg-tm-background"
                />
                <Input
                  name="password"
                  label="Password"
                  key="password"
                  placeholder="Enter password"
                  defaultValue={testPassword}
                  required
                  invalidMessage="Password is required"
                  type="password"
                  className="!bg-tm-background"
                />
              </>
            )}
            {tenantId ? <input type="hidden" name="tenantId" value={tenantId} required /> : null}

            {error && !loading ? <Alert variant="error">{error}</Alert> : null}

            <div className="flex items-center justify-end gap-1">
              <Button
                type="submit"
                loading={loading}
                disabled={isOnline === false}
                disabledDescription={
                  isOnline === false
                    ? "There must be a network connection in order to log into the machine center"
                    : ""
                }
              >
                Sign In
              </Button>
            </div>
          </Form>
        ) : (
          <Form className="flex flex-col gap-2">
            <Input
              label="Tenant Name"
              placeholder="Enter Tenant Name"
              name="tenantName"
              key="tenantName"
              autoFocus
              defaultValue={testTenant}
              required
              invalidMessage="Tenant name is required"
              className="!bg-tm-background"
            />

            {error && !loading ? <Alert variant="error">{error}</Alert> : null}

            <div className="flex justify-end">
              <Button
                type="submit"
                loading={loading}
                disabled={isOnline === false}
                disabledDescription={
                  isOnline === false
                    ? "There must be a network connection in order to log into the machine center"
                    : ""
                }
              >
                Go To Sign In Page
              </Button>
            </div>

            {redirectTo ? <input type="hidden" name="redirectTo" value={redirectTo} /> : null}
          </Form>
        )}
      </div>
      <WideForest />
    </section>
  );
}
